@import "./theme/variables.scss";
@import "./theme/loader.scss";
@import "./theme/fonts.scss";
@import "./theme/forms.scss";

@import "./layout.scss";
@import "./login.scss";
@import "./list.scss";
@import "./card.scss";
@import "./table.scss";

// Global Style
body {
  background: #f4f6f9;
  font-size: 16px;

  a {
    text-decoration: none !important;
    span {
      margin-left: 5px;
    }
    &:hover {
      color: $primary;
    }
  }
  .pointer {
    cursor: pointer;
  }
}
// Scroll
::-webkit-scrollbar {
  width: 0.02em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  outline: 1px solid rgba(255, 255, 255, 0.5);
}
