.card-header {
  background-color: transparent !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin-bottom: 0 !important;
}

.card-body {
  label {
    font-size: 1.1rem;
  }
}
