@font-face {
  font-family: "font-light";
  src: url("../../fonts/SourceSansPro-Light.ttf");
}

@font-face {
  font-family: "font";
  src: url("../../fonts/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "font-bold";
  src: url("../../fonts/SourceSansPro-Bold.ttf");
}

.font {
  font-family: "font" !important;
}

.font-light {
  font-family: "font-light" !important;
}

.font-bold {
  font-family: "font-bold" !important;
}
