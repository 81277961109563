.card {
  border: 0;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  .link {
    color: $primary;
    &:hover {
      color: #0a58ca;
      transition: 0.5s all ease-in-out;
    }
  }
}
